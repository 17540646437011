<template>
  <div>
    <b-card
      no-body
      class="sticky-top-65"
    >
      <b-card-header class="justify-content-between gap-2 py-1">
        <div class="d-flex align-items-end">
          <b-badge
            variant="light-primary"
            class="font-small-4 px-1 py-50"
          >
            BÁO CÁO
          </b-badge>
          <span class="ml-1 font-weight-bolder font-medium-1">{{ reportGeneralTitle }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <div class="d-flex mr-1 gap-1 align-items-center">
            <label class="text-nowrap">Tháng</label>
            <v-select
              v-model="monthFilter"
              class="select-size-sm"
              label="name"
              :options="$monthOptions"
              :clearable="true"
            />
          </div>
          <div class="d-flex gap-1 align-items-center">
            <label class="text-nowrap">Năm</label>
            <v-select
              v-model="yearFilter"
              class="select-size-sm"
              label="name"
              :options="$yearOptions"
              :clearable="true"
            />
          </div>
        </div>
      </b-card-header>
    </b-card>

    <table-h-s
      v-if="sumData.report_department_HS"
      :table-data="tableDataHS"
      :refs="sumData.report_department_HS.report_know_with"
      :time-filter="reportGeneralTitle"
    />

    <table-h-d
      v-if="sumData.report_department_HD"
      :table-data="tableDataHD"
      :refs="sumData.report_department_HD.report_know_with"
      :time-filter="timeFilter"
    />

    <table-thanh-ly
      v-if="sumData.report_with_liquid"
      :table-data="sumData.report_with_liquid"
      :time-filter="timeFilter"
    />

    <reports-teams
      :report-teams="sumData.report_deparment"
      :time-filter="reportGeneralTitle"
      @go-detail="goReportTeam"
      @open-modal-debt-detail="openModalDebtDetail"
    />

    <reports-users
      :report-users="reportUserWithSortId"
      :time-filter="reportGeneralTitle"
      @go-detail="goReportUserPKH"
    />

    <b-modal
      ref="modal-debt-detail"
      ok-title="Ok"
      ok-only
      size="xl"
      centered
      header-text-variant="primary"
      title="Chi tiết thu nợ"
    >
      <b-card-text>
        <b-table
          ref="refUsersListTable"
          class="position-relative"
          responsive
          hover
          small
          bordered
          primary-key="id"
          show-empty
          empty-text="Không có kết quả nào"
          :items="debtCollectionReportList"
          :fields="tableColumnsDebtCollectionReport"
          @row-clicked="gotoContractDetail"
        >
          <template #cell(code)="data">
            <span class="text-primary text-nowrap">
              {{ data.item.contract.contract_code || data.item.contract.document_code }}
            </span>
          </template>
          <template #cell(doi)="data">
            <span class="text-primary text-nowrap">{{ data.item.department && data.item.department.name }}</span>
          </template>
          <template #cell(ngay-thu)="data">
            <span class="text-nowrap">{{ formatDate(data.item.collect_at) }}</span>
          </template>
          <template #cell(thu-duoc)="data">
            <span class="text-primary text-nowrap">{{ formatNumber(data.item.amount) }} đ</span>
          </template>
          <template #cell(loai-no)="data">
            <span class="text-nowrap">{{ data.item.debt_type === 'origin' ? 'Nợ gốc' : 'Nợ lãi' }}</span>
          </template>
          <template #cell(phi-dv)="data">
            <span class="text-nowrap">{{ formatNumber(parseFloat(data.item.fee_to_buy_original || 0)) }} %</span>
          </template>
          <template #cell(tong-pdv)="data">
            <span class="text-primary text-nowrap">{{ formatNumber(parseFloat(data.item.amount_to_buy_original || 0)) }} đ</span>
          </template>
        </b-table>
        <br>
      </b-card-text>
    </b-modal>

    <!--    fixed btn-->
    <fixed-right-button
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ReportsTeams from '@/views/reports/overview/Teams.vue'
import ReportsUsers from '@/views/reports/overview/Users.vue'
import reportStoreModule from '@/views/reports/reportStoreModule'
import vSelect from 'vue-select'
import FixedRightButton from '@core/components/fixed-button/Fixed-Right-Button.vue'
import http from '@/global/http'
import TableHS from '@/views/reports/overview/TableHS.vue'
import TableHD from '@/views/reports/overview/TableHD.vue'
import TableThanhLy from '@/views/reports/overview/TableThanhLy.vue'
import planStoreModule from '@/views/plans/planStoreModule'
import departmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import useReportsOverview from './useReportsOverview'

export default {
  components: {
    BTable,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    BCardText,
    ReportsUsers,
    BCard,
    BCardHeader,
    BBadge,
    vSelect,
    ReportsTeams,
    FixedRightButton,
    TableHS,
    TableHD,
    TableThanhLy,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    tableColumnsDebtCollectionReport() {
      return [
        {
          label: 'Mã',
          key: 'code',
        },
        {
          label: 'Tên đội',
          key: 'doi',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Ngày thu',
          key: 'ngay-thu',
          stickyColumn: true,
        },
        {
          label: 'Thu được',
          key: 'thu-duoc',
        },
        {
          label: 'Loại',
          key: 'loai-no',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Phí DV',
          key: 'phi-dv',
        },
        {
          label: 'Tổng PDV',
          key: 'tong-pdv',
        },
      ]
    },
    reportUserWithSortId() {
      const res = this.sumData.report_user || []
      const sumRow = {
        contract_total: res.reduce((p, c) => p + (parseFloat(c.contract_total) || 0), 0),
        count: res.reduce((p, c) => p + (parseFloat(c.count) || 0), 0),
        count_total: res.reduce((p, c) => p + (parseFloat(c.count_total) || 0), 0),
        creator_id: '',
        debt_buy_original_total: res.reduce((p, c) => p + (parseFloat(c.debt_buy_original_total) || 0), 0),
        debt_count: res.reduce((p, c) => p + (parseFloat(c.debt_count) || 0), 0),
        debt_total: res.reduce((p, c) => p + (parseFloat(c.debt_total) || 0), 0),
        hd_sum_interest: res.reduce((p, c) => p + (parseFloat(c.hd_sum_interest) || 0), 0),
        hd_sum_origin: res.reduce((p, c) => p + (parseFloat(c.hd_sum_origin) || 0), 0),
        sum_buy_interest: res.reduce((p, c) => p + (parseFloat(c.sum_buy_interest) || 0), 0),
        sum_buy_original: res.reduce((p, c) => p + (parseFloat(c.sum_buy_original) || 0), 0),
        sum_interest: res.reduce((p, c) => p + (parseFloat(c.sum_interest) || 0), 0),
        sum_origin: res.reduce((p, c) => p + (parseFloat(c.sum_origin) || 0), 0),
        user_name: 'Tổng',
      }
      return [...res.sort((a, b) => (a.creator_id < b.creator_id ? -1 : 1)), sumRow]
    },
    actionsButton() {
      return [
        {
          label: 'Xuất excel',
          icon: 'DownloadIcon',
          value: 'export-excel-report-overview',
        },
      ]
    },
    timeFilter() {
      if (!this.yearFilter) return 'Tất cả'
      if (this.monthFilter) return `T${this.monthFilter}/${this.yearFilter}`
      return `năm ${this.yearFilter}`
    },
    tableDataHS() {
      const tableData = this.sumData.report_department_HS?.departments || []
      const sumRow = { ...this.sumData.report_department_HS?.sum_row || {}, department_name: 'Tổng' }
      return [...tableData, sumRow]
    },
    tableDataHD() {
      const tableData = this.sumData.report_department_HD?.departments || []
      const sumRow = { ...this.sumData.report_department_HD?.sum_row || {}, department_name: 'Tổng' }
      return [...tableData, sumRow]
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.reportMode = this.$route.name
        const q = this.$route.query
        if (q.monthFilter !== undefined && q.yearFilter !== undefined) {
          this.monthFilter = q.monthFilter || ''
          this.yearFilter = q.yearFilter || ''
        }
      },
    },
    reportGeneralTitle() {
      const q = this.$route.query
      if (this.monthFilter !== q.monthFilter || this.yearFilter !== q.yearFilter) {
        this.$router.push({ path: 'reports', query: { monthFilter: this.monthFilter || '', yearFilter: this.yearFilter || '', t: new Date().getTime() } })
      }
    },
  },
  methods: {
    debtTypeToText(type) {
      if (!type) return ''
      if (type === 'origin') return 'Gốc'
      return 'Lãi'
    },
    gotoContractDetail(debtContract) {
      const id = debtContract.contract_id || debtContract.contract.id
      this.$router.push({
        name: 'contracts-detail',
        params: { id },
        query: { t: Date.now() },
      })
    },
    openModalDebtDetail(data) {
      this.$refs['modal-debt-detail'].show()
      this.fetchDebtCollectionDetailReport(data.department_id)
    },
    handleActionsButton(btn) {
      if (btn.value === 'export-excel-report-overview') {
        const { from, to } = this.getDateRange()
        store.dispatch('app/exportExcelReportOverview', http.buildQuery({
          from: http.getISODate(from),
          to: http.getISODate(to),
        })).then(res => {
          this.openLinkInNewTab(res.data.data)
        })
      }
    },
    goReportTeam(d) {
      this.$router.push({
        name: 'reports-team',
        params: { id: d.department_id },
        query: {
          name: d.department_name, monthFilter: this.monthFilter, yearFilter: this.yearFilter, t: new Date().getTime(),
        },
      })
    },
    goReportUserPKH(d) {
      const q = this.$route.query
      this.$router.push({
        name: 'reports-user-pkh',
        params: { id: d.creator_id },
        query: {
          name: d.user_name, type: 'user-pkh', monthFilter: q.monthFilter, yearFilter: q.yearFilter, t: new Date().getTime(),
        },
      })
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    const PLAN_STORE_MODULE_NAME = 'plan'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, departmentStoreModule)
    if (!store.hasModule(PLAN_STORE_MODULE_NAME)) store.registerModule(PLAN_STORE_MODULE_NAME, planStoreModule)
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
      if (store.hasModule(PLAN_STORE_MODULE_NAME)) store.unregisterModule(PLAN_STORE_MODULE_NAME)
    })

    return {
      ...useReportsOverview(),
    }
  },
}
</script>

<style lang="scss">
</style>
